import React, { useEffect, useMemo, useReducer, useState } from "react";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//utils
import { stageList } from "./data";
import { boardContext } from "../../../App";
import { useAxios } from "../../../core/useAxios";
import { APIMETHOD, menuItemsIAF, menuItemsLikelihoodIndicator, clientStatus } from "../../../core/constant";
import STRING from "../../../core/strings";

//component
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { isEmailValid } from "../../../shared/helpers";
import { TabContext, TabList } from "@mui/lab";
import {
  ReminderReducer,
  initialState as initialReminderState,
} from "../../../reducers/reminderReducer";
import {
  deleteReminder,
  fetchReminders,
  fetchRemindersByClientId,
  patchReminder,
  postReminder,
} from "../../../reducers/reminderReducer/reminderActions";
import { get } from "http";
import ClientAddress, { addressReducer, initialClientAddress } from "./clientaddress";
import AlertMsg from "../../../shared/components/AlertMsg";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormLabel } from '@mui/material';

interface taskDiaglog {
  isOpen: boolean;
  handleClose: (data: any) => void;
  editDetails?: any;
  taskIndex: number;
}

interface AddTaskType {
  type: ActionName;
  payload: any;
}

enum ActionName {
  SET_EMAIL_ERROR,
  SET_CLIENT_DETAILS,
}

const initialState = {
  clientDetails: {
    client_name: "",
    notes: null,
    stage: 16,
    salesperson: null,
    representative_name: null,
    main_address_city: null,
    main_address_country: null,
    main_address_state: null,
    main_address_street: null,
    main_address_zip: null,
    timeframe_indicator: null,
    application_date: null,
    job_won_date: null,
    audit_start_date: null,
    contact_email: null,
    contact_phone: null,
    number_of_locations: 1,
    website: null,
    scope_of_registration: null,
    number_of_temporary_sites: 0,
    standards: null,
    operational_processes: null,
    outsourced_processes: null,
    audit_anniversary: null,
    assigned_auditor: null,
    referred_by: null,
    referred_by_2: null,
    day_rate: 0.0,
    proposal_amount: 0.0,
    total_full_time_employee: 0,
    shifts: 1,
    activities_at_shifts: null,
    stage_1_audit_days: 0,
    stage_2_audit_days: 0,
    is_multi_site: false,
    exclusions: null,
    mobile_phone_number: null,
    legal_status: null,
    latest_audit_completion_date: null,
    movement_date: null,
    calculator_parameters: {
      calculator_scope: "",
      calculator_process: "",
      calculator_maturity_mgmt_sys: false,
      calculator_client_preparedness: false,
      calculator_high_level_of_automation: false,
      calculator_off_location_personnel: false,
      calculator_requires_interpreter: false,
      calculator_visiting_temporary_sites: false,
      calculator_high_degree_of_regulation: false,
      calculator_already_certified_standards: false,
      calculator_size_of_site_for_number_of_personnel: "",
      calculator_design_responsible: false,
      calculator_outsourced_functions: false,
      calculator_risk_category: "",
      calculator_customers_with_critical_info: "",
      calculator_it_infrastructure_complexity: "",
      calculator_dependency_on_outsourcing_suppliers: "",
      calculator_information_system_dev_in_house: "",
      calculator_outsourced_functions_ems: false,
      calculator_high_sensitivity_environment: false,
      calculator_additional_environmental_aspects: false,
      calculator_high_risk: false,
      calculator_views_of_interested_parties: false,
      calculator_rate_of_accidents: false,
      calculator_public_presence: false,
      calculator_legal_proceedings: false,
      calculator_large_presence_of_subcontractors: false,
      calculator_dangerous_substances: false,
      calculator_unknown_legislation_and_language: false,
      calculator_views_of_interested_parties_ohsms: false,
    },
  },
  emailError: false,
};

let userCache: any = {};

const addTaskReducer = (state = initialState, action: AddTaskType) => {
  switch (action.type) {
    case ActionName.SET_CLIENT_DETAILS:
      return { ...state, clientDetails: action.payload };
    case ActionName.SET_EMAIL_ERROR:
      return { ...state, emailError: action.payload };

    default:
      return state;
  }
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const clientEventInitialState = {
  reminders: [],
  isLoading: false,
  error: null,
};

export default function AddTask(props: taskDiaglog) {
  const { isOpen, handleClose, editDetails, taskIndex } = props;
  const [state, actionDispatch] = useReducer(addTaskReducer, initialState);
  const clientDetails = state.clientDetails;
  const [isConfirmation, setIsConfirmation] = useState(0);
  const { boardData, setBoardData, config } = React.useContext(boardContext);
  const [nestedModal, setNestedModal] = useState<boolean>(false);
  const [lostReason, setLostReason] = useState<string>();
  const [value, setValue] = React.useState(0);
  const [assignedUser, setAssignedUser] = React.useState<any>(null);
  // const [assignedUserName, setAssignedUserName] = React.useState<any>(null);
  const [reminderData, setReminderData] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const [isEditReminder, setIsReminderEdit] = useState<boolean>(false);
  const [clientAdressList, setClientAdressList] = useState<any>([]);
  const [isSendReminder, setIsSendReminder] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState(false);
  const [clientAdressState, dispatchClientAddress] = useReducer(addressReducer, initialClientAddress);
  const [deleteReminderData,setDeleteRemainderData] = useState(null);
  const defaultProps = {
    options: userList,
    getOptionLabel: (option: any) => option.name,
  };
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const stage_List = useMemo(
    () => stageList(boardData?.columns || []),
    [boardData?.columns]
  );
  const boardLength = boardData?.columns?.length || 0;
  const { post, put, error, del, get } = useAxios({ showLoader: true });
  // const stage_List = stageList(boardData?.columns || []);

  const [isError, setIsError] = useState(false);
  const [errorKey, setErrorKey] = useState("");
  const [errorValue, setErrorValue] = useState("");

  useEffect(() => {
      if(error && error.responseData){
        const keys = Object.keys(error.responseData);
        if (keys.length > 0) {
          const firstKey = keys[0];
          const firstValue = error.responseData[firstKey];
          setErrorKey(firstKey)
          setErrorValue(firstValue)
          setIsError(true)
        }
        return;
      }
  }, [error]);

  const handleCloseTask = () => {
    handleClose(false);
  };

  const handleAddAddress = async () => {
    console.log("Handle Add address");
  };

  const defaultReminderToAdd = {
    is_done: false,
    action: "",
    due_date: new Date(),
    notes: "",
  };
  const [reminderToAdd, setReminderToAdd] = useState(defaultReminderToAdd);
  const [remindersState, dispatchReminders] = useReducer(
    ReminderReducer,
    initialReminderState
  );

  const handleAddReminder = async () => {
    console.log("Handle Add Reminder", { ...reminderToAdd, clientDetails });
    if(isEditReminder) {
      const updateReminder: any = await patchReminder(
        dispatchReminders,
        {
          ...reminderToAdd,
          due_date: moment(reminderToAdd.due_date).format("YYYY-MM-DD"),
          client: clientDetails.id,
          client_name: clientDetails.client_name,
          assigned_to: assignedUser?.id
        },
        config?.getToken()
      );
      if (updateReminder) {
        setIsReminderEdit(false);
        setReminderToAdd(defaultReminderToAdd);
      }
    } else {
      postReminder(
        dispatchReminders,
        {
          ...reminderToAdd,
          due_date: moment(reminderToAdd.due_date).format("YYYY-MM-DD"),
          client: clientDetails.id,
          client_name: clientDetails.client_name,
          assigned_to: assignedUser?.id
        },
        config?.getToken()
      );
    }

    setReminderToAdd(defaultReminderToAdd);
  };

  const handleEditReminder = (reminderData: any) => {
    const reminder_object = {
      ...reminderData,
      due_date: new Date(reminderData.due_date)
    }
    const assignedUserObj = userList.filter((x: any) => x.id === reminderData.assigned_to);
    if(assignedUserObj && assignedUserObj.length > 0) {
      setAssignedUser(assignedUserObj[0]);
    } else {
      setAssignedUser(null);
    }
    setReminderToAdd(reminder_object);
    setIsReminderEdit(true);
  }

  const handleDoneReminder = async (event:any , reminderData:any) => {
   // console.log(event, reminderData);
    const updateReminder: any = await patchReminder(
      dispatchReminders,
      {
        ...reminderData,
        [event.target.name]: event.target.checked
      },
      config?.getToken()
    );

    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleDeleteReminder = (reminderData: any) => {
    deleteReminder(
      dispatchReminders,
      {
        ...reminderData,
        client: clientDetails.id,
        client_name: clientDetails.client_name,
      },
      config?.getToken()
    );
  }

  

  useEffect(() => {
    fetchReminders(dispatchReminders, config?.getToken(), editDetails?.id);
  }, []);

  useEffect(() => {
    const getAdress = async () => {
      const res = await getClientsAddress();
      if(res && res?.results && res?.results?.length > 0) {
        setClientAdressList(res?.results);
      } else {
        setClientAdressList([])
      }
    }
    if(editDetails?.id) {
      getAdress();
      getReminderUser();
    }
    
  }, []);

  const getReminderUser = async () => {
    const res = await getUserList();
    if(res && res?.results && res?.results?.length > 0) {
      setUserList(res?.results);
      defaultProps.options = res?.results;
    } else {
      setUserList([])
    }
  }

  useEffect(() => {
    if (JSON.stringify(editDetails) !== "{}" && editDetails) {
      const sid: string = stage_List[editDetails?.boardIndex]?.stageid;
      const payload = {
        ...editDetails,
        stage: sid,
      };
      if (editDetails.audit_anniversary) {
        payload.audit_anniversary = moment(
          editDetails.audit_anniversary
        ).toDate();
      }
      if (editDetails.timeframe_indicator) {
        payload.timeframe_indicator = moment(
          editDetails.timeframe_indicator
        ).toDate();
      }
      if (editDetails.application_date) {
        payload.application_date = moment(
          editDetails.application_date
        ).toDate();
      }
      if (editDetails.job_won_date) {
        payload.job_won_date = moment(
          editDetails.job_won_date
        ).toDate();
      }
      if (editDetails.audit_start_date) {
        payload.audit_start_date = moment(
          editDetails.audit_start_date
        ).toDate();
      }
      if (editDetails.latest_audit_completion_date) {
        payload.latest_audit_completion_date = moment(
          editDetails.latest_audit_completion_date
        ).toDate();
      }
      if (editDetails.movement_date) {
        payload.movement_date = moment(
          editDetails.movement_date
        ).toDate();
      }
      actionDispatch({
        type: ActionName.SET_CLIENT_DETAILS,
        payload: payload,
      });
    }
  }, [editDetails]);

  const addTaskToList = async (shouldClose: boolean) => {
    if (
      clientDetails.contact_email &&
      !isEmailValid(clientDetails.contact_email)
    ) {
      actionDispatch({ type: ActionName.SET_EMAIL_ERROR, payload: true });
      return;
    }
    const index: number = boardData?.columns?.findIndex(
      (x: any) => x.id === clientDetails.stage
    );
    const boardList = [...boardData.columns];
    const data: any = { ...clientDetails, lostReason };
    if (clientDetails.audit_anniversary) {
      data.audit_anniversary = moment(clientDetails.audit_anniversary).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.timeframe_indicator) {
      data.timeframe_indicator = moment(clientDetails.timeframe_indicator).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.application_date) {
      data.application_date = moment(clientDetails.application_date).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.job_won_date) {
      data.job_won_date = moment(clientDetails.job_won_date).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.audit_start_date) {
      data.audit_start_date = moment(clientDetails.audit_start_date).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.latest_audit_completion_date) {
      data.latest_audit_completion_date = moment(clientDetails.latest_audit_completion_date).format(
        "YYYY-MM-DD"
      );
    }
    if (clientDetails.movement_date) {
      data.movement_date = moment(clientDetails.movement_date).format(
        "YYYY-MM-DD"
      );
    }
    if (editDetails?.id) {
      const preIndex = boardData?.columns?.findIndex(
        (x: any) => x.id === editDetails.stage
      );
      const res: any = await EditClientsAPICall(data);
      if (res) {        
        boardList[preIndex].cards.splice(taskIndex, 1);
        boardList[index].cards.splice(taskIndex, 0, res);
      }
      else{
        return;
      }
    } else {
      // With API call
      const res: any = await addClientsAPICall(data);
      if (res) {
        setIsEdited(true);
        data.clientname = res.client_name;
        data.id = res.id;
        boardList[index].cards.push(res);
        if (res.stage == 16){
          const resp = await get({
            url: APIMETHOD.UPDATE_SALES_REPORT, params: {main_address_country: res.main_address_country}
          });
        }
      }
      else{
        return;
      }
    }
    setIsEdited(true);
    const updateData = { ...boardData };
    updateData.columns = boardList;
    setBoardData(updateData);
    config.setData(updateData);
    if (shouldClose) {
      setTimeout(() => {
        handleClose(false);
      }, 1000);
    }
  };

  const getAssignedTo = (assigned_to: any) => {
    if(!userCache[assigned_to]) {
      const data = userList.filter((x: any) => x.id === assigned_to);
      if(data && data.length > 0) {
        userCache[assigned_to] = data[0].name;
        return data[0].name;
      }
      return '';
    }
    return userCache[assigned_to];
  };

  const getReminderBySpecificClient = () => {
    const filterData = remindersState.reminders.filter((item:any) => item.id == editDetails.client);
    setReminderData(filterData);
  }

  const addClientsAPICall = async (data: any) => {
    data.movement_date = moment().format('YYYY-MM-DD');
    const response: any = await post({
      url: APIMETHOD.clients.ADD_CLIENT,
      data: data,
    });
    if (response) {
      return response;
    }
  };

  const getClientsAddress = async () => {
    let _url = '';
    if(editDetails?.id) {
      _url = APIMETHOD.address.ADDRESS_API + '?client=' + editDetails?.id;
    } else {
      _url = APIMETHOD.address.ADDRESS_API;
    }
    const response: any = await get({
      url: _url,
    });
    if (response) {
      return response;
    }
  };

  const getUserList = async () => {
    const response: any = await get({
      url: APIMETHOD.reminder.REMINDER_USER,
    });
    if (response) {
      return response;
    }
  };

  const EditClientsAPICall = async (data: any) => {
    const response: any = await put({
      url: APIMETHOD.clients.EDIT_CLIENT + data.id + "/",
      data: data,
    });
    if (response) {      
      return response;
    }
  };

  const MovetoLostAPICall = async (data: any) => {
    const response: any = await post({
      url: APIMETHOD.clients.MOVE_LOST,
      data: data,
    });
    if (response) {
      return response;
    }
  };

  const sendReminder = async (reminderId: any) => {
    const result: any = await post({url: APIMETHOD.reminder.SEND_REMINDER, data: {reminder_id: reminderId}});
    if(result) {
      setIsSendReminder(true);
    }
  }

  const deleteClient = (editDetails: any, taskIndex: any) => {
    setIsConfirmation(1);
  };

  const showDeleteReminderConfirm = (reminderData: any) => {
    setDeleteRemainderData(reminderData);
    setIsConfirmation(2);
  }

  const confirmDiloagClose = () => {
    setIsConfirmation(0);
  };

  const confirmDelete = async () => {
    if (isConfirmation === 1) {
      const res: any = await deleteClientAPICall(editDetails.id);
      if (!res && editDetails) {
        const clientlist = [...boardData.columns[editDetails.boardIndex].cards];
        clientlist.splice(taskIndex, 1);
        boardData.columns[editDetails.boardIndex].cards = [...clientlist];
        const updatedBoard = { ...boardData };
        setBoardData(updatedBoard);
        config.setData(updatedBoard);
        confirmDiloagClose();
      }
    } else if (isConfirmation === 2) {
      handleDeleteReminder(deleteReminderData);
      confirmDiloagClose();
    }
  };

  const deleteClientAPICall = async (id: any) => {
    const response: any = await del({
      url: APIMETHOD.clients.ADD_CLIENT + id + "/",
    });
    return response;
  };

  const moveToLost = async (editDetails: any, taskIndex: number) => {
    setNestedModal(false); // hide nested modal for getting reason
    const boardList = [...boardData.columns];
    const lostIndex = boardList.findIndex((board) => board.id === 31);

    const currentBoardListIdx = boardList.findIndex(
      (board) => board.id === editDetails.stage
    );
    const cardIdx = boardList[currentBoardListIdx].cards.findIndex(
      (c: any) => c.id === editDetails.id
    );

    const res: any = await MovetoLostAPICall({
      client_id: editDetails.id,
      lost_reason: lostReason
    });

    if (res) {
      editDetails.lost_reason = lostReason;
      boardList[currentBoardListIdx].cards.splice(cardIdx, 1);
      boardList[lostIndex].cards.push(editDetails);
      const updateData = { ...boardData };
      updateData.columns = boardList;
      setBoardData(updateData);
      config.setData(updateData);
      handleClose(false);
    }
  };

  const emailChange = (e: any) => {
    if (state.emailError) {
      actionDispatch({ type: ActionName.SET_EMAIL_ERROR, payload: false });
    }
    actionDispatch({
      type: ActionName.SET_CLIENT_DETAILS,
      payload: { ...clientDetails, contact_email: e.target.value },
    });
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <AlertMsg
          isOpen={isSendReminder}
          handleClose={() => setIsSendReminder(false)}
          message={`Reminder Sent Successfully!!!`}
          severity="success"
        />
      <AlertMsg
        isOpen={isEdited}
        handleClose={() => setIsEdited(false)}
        message={`Client details saved successfully!!!`}
        severity="success"
      />
      <AlertMsg
        isOpen={isError}
        handleClose={() => setIsError(false)}
        message={String(errorKey).toUpperCase()+": "+errorValue}
        severity="success"
      />
      <ConfirmationDialog
        isOpen={isConfirmation !== 0 }
        handleClose={confirmDiloagClose}
        handleConfirm={confirmDelete}
      />      
      <Dialog open={isOpen} onClose={handleCloseTask} fullWidth={true}>
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
        >
        <DialogTitle> Enter client details</DialogTitle>
        <DialogActions>
        <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "10px",
              paddingLeft: "20px",
            }}
          >
            {editDetails && (
              <>
                <Button
                  sx={{ float: "right" }}
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => deleteClient(editDetails, taskIndex)}
                >
                  Delete Client
                </Button>
                <Button
                  sx={{ float: "right", mr: 0.5 }}
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={() => setNestedModal(true)}
                  disabled={editDetails.stage === 31}
                >
                  Move To Lost
                </Button>
                <Dialog
                  open={nestedModal}
                  onClose={() => setNestedModal(false)}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    Reason for client lost
                  </DialogTitle>
                  <DialogContent>
                    <FormControl variant="standard" sx={{ m: 1 }} fullWidth>
                      <InputLabel id="select-reason">
                        Reason for losing
                      </InputLabel>
                      <Select
                        labelId="select-reason"
                        id="reason-select-box"
                        type={"string"}
                        label="Reason for losing"
                        onChange={(e: any) => setLostReason(e.target.value)}
                      >
                        <MenuItem value="Awarded to other Auditor">
                          Awarded to other Auditor
                        </MenuItem>
                        <MenuItem value="No longer pursuing">
                          No longer pursuing
                        </MenuItem>
                        <MenuItem value="Wanted a local auditor">
                          Wanted a local auditor
                        </MenuItem>
                        <MenuItem value="Didn't respond on time">
                          Didn't respond on time
                        </MenuItem>
                        <MenuItem value="Unhappy with the paperwork">
                          Unhappy with the paperwork
                        </MenuItem>
                        <MenuItem value="Wanted a remote auditor">
                          Wanted a remote auditor
                        </MenuItem>
                        <MenuItem
                          value="Others (Provide the reason explicitly, if user chooses
                        Others)"
                        >
                          Others
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    {clientDetails.stage !== 31 ? (
                      <>
                        <Button autoFocus onClick={() => setNestedModal(false)}>
                          Close
                        </Button>
                        <Button
                          onClick={() => moveToLost(editDetails, taskIndex)}
                          disabled={!lostReason}
                          autoFocus
                        >
                          Move To Lost
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => setNestedModal(false)}
                        disabled={!lostReason}
                      >
                        Next
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </>
            )}
        </Box>
        </DialogActions>
        </Box>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              aria-label="edit client details"
            >
              <Tab label="Details" {...a11yProps(0)} />
              <Tab disabled={editDetails?.id ? false : true} label="Address" {...a11yProps(1)} />
              <Tab disabled={editDetails?.id ? false : true} label="Action / Reminder" {...a11yProps(2)}/>
              <Tab disabled={editDetails?.id ? false : true} label="Notes" {...a11yProps(3)}/>
              <Tab disabled={editDetails?.id ? false : true} label="Movement Date" {...a11yProps(4)}/>
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <FormControl variant="standard" sx={{ width: "100%" }} fullWidth>
                <TextField
                  margin="dense"
                  id="cname"
                  label={STRING.ADD_TASK.CLIENT_NAME}
                  type="text"
                  value={clientDetails.client_name}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        client_name: e.target.value,
                      },
                    })
                  }
                  fullWidth
                  variant="standard"
                />
              </FormControl>
              
              <FormControl variant="standard" sx={{ width: "100%", mt: 2}} fullWidth>
                <InputLabel id="stagek-stage-select">
                  {STRING.ADD_TASK.STAGE}
                </InputLabel>
                <Select
                  labelId="stagek-stage-select"
                  id="stage"
                  value={clientDetails.stage! ?? 16}
                  onChange={(e) => {
                    if (e.target.value === 31) setNestedModal(true);
                    return actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, stage: e.target.value },
                    });
                  }}
                  label={STRING.ADD_TASK.SELECT_STAGE}
                  type={"integer"}
                >
                  {stage_List?.map((s: any) => (
                    <MenuItem value={s.stageid} key={s.stageid}>
                      {s.stagename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                  <InputLabel id="other-registrations-select">
                    {STRING.ADD_TASK.CERTIFICATION_BODY}
                  </InputLabel>
                  <Select
                    labelId="other-registrations-select"
                    id="certification_body"
                    value={clientDetails.certification_body || ""}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: { ...clientDetails, certification_body: e.target.value },
                      })
                    }
                  >
                    <MenuItem value="SCK">SCK</MenuItem>
                    <MenuItem value="ARS">ARS</MenuItem>
                  </Select>
                </FormControl>
                </Grid>

              {clientDetails.stage === 42 && (
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                  <InputLabel id="client-status-select">
                    {STRING.ADD_TASK.CLIENT_STATUS}
                  </InputLabel>
                  <Select
                    labelId="client-status-select"
                    id="client_status"
                    value={clientDetails.client_status || ""}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: { ...clientDetails, client_status: e.target.value },
                      })
                    }
                    label={STRING.ADD_TASK.SELECT_CLIENT_STATUS}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {clientStatus.find(item => item.value === selected)?.label}
                        <div style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: clientStatus.find(item => item.value === selected)?.color,
                          marginLeft: '8px',
                        }} />
                      </div>
                    )}
                  >
                    {clientStatus.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.label}
                          <div style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: item.color,
                            marginLeft: '8px',
                          }} />
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              )}

              {clientDetails.stage === 7 && (
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                  <InputLabel id="likelihood-indicator-select">
                    {STRING.ADD_TASK.LIKELIHOOD_INDICATOR}
                  </InputLabel>
                  <Select
                    labelId="likelihood-indicator-select"
                    id="likelihood_indicator"
                    value={clientDetails.likelihood_indicator || ""}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: { ...clientDetails, likelihood_indicator: e.target.value },
                      })
                    }
                    label={STRING.ADD_TASK.SELECT_LIKELIHOOD_INDICATOR}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {menuItemsLikelihoodIndicator.find(item => item.value === selected)?.label}
                        <div style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: menuItemsLikelihoodIndicator.find(item => item.value === selected)?.color,
                          marginLeft: '8px',
                        }} />
                      </div>
                    )}
                  >
                    {menuItemsLikelihoodIndicator.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.label}
                          <div style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: item.color,
                            marginLeft: '8px',
                          }} />
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              )}

              {clientDetails.stage === 7 && (
              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.timeframe_indicator}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, timeframe_indicator: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="timeframe_indicator"
                      label={STRING.ADD_TASK.TIMEFRAME_INDICATOR}
                      type="text"
                      value={clientDetails.timeframe_indicator}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>
              )}

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.application_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, application_date: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="application_date"
                      label={STRING.ADD_TASK.APPLICATION_DATE}
                      type="text"
                      value={clientDetails.application_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.job_won_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, job_won_date: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="job_won_date"
                      label={STRING.ADD_TASK.JOB_WON_DATE}
                      type="text"
                      value={clientDetails.job_won_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>

              {(clientDetails.stage === 4 || clientDetails.stage === 25) && (
              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.audit_start_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, audit_start_date: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="audit_start_date"
                      label={STRING.ADD_TASK.AUDIT_START_DATE}
                      type="text"
                      value={clientDetails.audit_start_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>
              )}

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.latest_audit_completion_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, latest_audit_completion_date: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="latest_audit_completion_date"
                      label={STRING.ADD_TASK.LATEST_AUDIT_COMPLETION_DATE}
                      type="text"
                      value={clientDetails.latest_audit_completion_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="salesperson"
                  type="text"
                  value={clientDetails.salesperson}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.SALESPERSON}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        salesperson: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.representative_name}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.REPRESENTATIVE_NAME}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        representative_name: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  error={state.emailError}
                  value={clientDetails.contact_email}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.CONTACT_EMAIL}
                  onChange={(e) => emailChange(e)}
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.contact_phone}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.CONTACT_PHONE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        contact_phone: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>       
                     
              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="main_address_street"
                  type="text"
                  value={clientDetails.main_address_street}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MAIN_STREET}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        main_address_street: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="main_address_city"
                  type="text"
                  value={clientDetails.main_address_city}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MAIN_CITY}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        main_address_city: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="main_address_state"
                  type="text"
                  value={clientDetails.main_address_state}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MAIN_STATE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        main_address_state: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="main_address_zip"
                  type="text"
                  value={clientDetails.main_address_zip}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MAIN_ZIP}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        main_address_zip: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="main_address_country"
                  type="text"
                  value={clientDetails.main_address_country}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MAIN_COUNTRY}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        main_address_country: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="rName"
                  type="number"
                  value={clientDetails.number_of_locations}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.NUMBER_LOCATION}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        number_of_locations: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.website}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.WEBSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, website: e.target.value },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.scope_of_registration}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.SCOPE_REGISTRATION}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        scope_of_registration: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.number_of_temporary_sites}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.NUMBER_TEMPORARY_SITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        number_of_temporary_sites: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="rName"
                  type="text"
                  value={clientDetails.standards}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STANDARDS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        standards: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="operationProcess"
                  type="text"
                  value={clientDetails.operational_processes}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.OPERATION_PROCESS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        operational_processes: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="outSourcedProcess"
                  type="text"
                  value={clientDetails.outsourced_processes}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.OUTSOURCED_PROCESS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        outsourced_processes: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <DatePicker
                  selected={clientDetails.audit_anniversary}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, audit_anniversary: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="audit_anniversary"
                      label={STRING.ADD_TASK.AUDIT_ANIVERSARY}
                      type="text"
                      value={clientDetails.audit_anniversary}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="assigned_auditor"
                  type="text"
                  value={clientDetails.assigned_auditor}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.ASSIGNED_AUDITOR}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        assigned_auditor: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="referred_by"
                  type="text"
                  value={clientDetails.referred_by}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.REFERED_BY}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        referred_by: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="referred_by_2"
                  type="text"
                  value={clientDetails.referred_by_2}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.REFERED_BY_2}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        referred_by_2: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="day_rate"
                  type="text"
                  value={clientDetails.day_rate}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.DAY_RATE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, day_rate: e.target.value },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="proposal_amount"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                      e.preventDefault()
                    }
                  }}
                  value={clientDetails.proposal_amount}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.PROPOSAL_AMOUNT}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, proposal_amount: e.target.value },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="total_full_time_employee"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                      e.preventDefault()
                    }
                  }}
                  value={clientDetails.total_full_time_employee}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.TOTAL_NO_OF_FULL_TIME_EQ_EMPLOYEES}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, total_full_time_employee: e.target.value },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                // sx={{ p: 1, marginRight: "100px" }}
                sx={{ width: "100%"}}
              >
                <TextField
                  margin="dense"
                  id="shifts"
                  type="number"
                  value={clientDetails.shifts}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.SHIFTS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, shifts: e.target.value },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="activityShift"
                  type="text"
                  value={clientDetails.activities_at_shifts}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.ACTIVITES_AT_SHIFT}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        activities_at_shifts: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="stage_1_audit_days"
                  type="number"
                  value={clientDetails.stage_1_audit_days}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_ONE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_1_audit_days: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="stage_2_audit_days"
                  type="number"
                  value={clientDetails.stage_2_audit_days}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_TWO}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_2_audit_days: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              {/*<Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="stageOneSite"
                  type="number"
                  value={clientDetails.stage_I_audit_days_onsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_ONE_ONSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_I_audit_days_onsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="stage_I_audit_days_offsite"
                  type="number"
                  value={clientDetails.stage_I_audit_days_offsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_ONE_OFFSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_I_audit_days_offsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="stage_II_days_onsite"
                  type="number"
                  value={clientDetails.stage_II_days_onsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_TWO_ONSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_II_days_onsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="shifts"
                  type="number"
                  value={clientDetails.stage_II_days_offsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STAGE_TWO_OFFSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        stage_II_days_offsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>*/}

              {/*<Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="surveillance_days_onsite"
                  type="number"
                  value={clientDetails.surveillance_days_onsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.SURVEILLANCE_ONSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        surveillance_days_onsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="surveillance_days_offsite"
                  type="number"
                  value={clientDetails.surveillance_days_offsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.SURVEILLANCE_OFFSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        surveillance_days_offsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>*/}

              {/*<Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="recertification_days_onsite"
                  type="number"
                  value={clientDetails.recertification_days_onsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.RECERTIFICATION_ONSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        recertification_days_onsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="recertification_days_offsite"
                  type="number"
                  value={clientDetails.recertification_days_offsite}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.RECERTIFICATION_OFFSITE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        recertification_days_offsite: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>*/}

              <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%"}}
                // sx={{ p: 1, marginRight: "100px" }}
              >
                <TextField
                  margin="dense"
                  id="reason_for_lost"
                  type="text"
                  value={editDetails?.lost_reason}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.REASON_FOR_LOST_CLIENT}
                  disabled={true}
                />
              </FormControl>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails.is_multi_site}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          is_multi_site: !clientDetails.is_multi_site,
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.IS_MULTISITE}
              />

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="exclusions"
                  type="text"
                  value={clientDetails.exclusions}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.EXCLUSIONS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        exclusions: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="mobile_phone_number"
                  type="text"
                  value={clientDetails.mobile_phone_number}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.MOBILE_PHONE_NUMBER}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        mobile_phone_number: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                <InputLabel id="legal-status-select">
                  {STRING.ADD_TASK.LEGAL_STATUS}
                </InputLabel>
                <Select
                  labelId="legal-status-select"
                  id="legal_status"
                  value={clientDetails.legal_status || ""}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, legal_status: e.target.value },
                    })
                  }
                  label={STRING.ADD_TASK.SELECT_LEGAL_STATUS}
                >
                  <MenuItem value="Public Ltd.">Public Ltd.</MenuItem>
                  <MenuItem value="Pvt. Ltd.">Pvt. Ltd.</MenuItem>
                  <MenuItem value="Proprietorship">Proprietorship</MenuItem>
                  <MenuItem value="Partnership">Partnership</MenuItem>
                  <MenuItem value="Trust">Trust</MenuItem>
                  <MenuItem value="Society">Society</MenuItem>
                  <MenuItem value="G.O.">G.O.</MenuItem>
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="statutory_regulatory_requirements"
                  type="text"
                  value={clientDetails.statutory_regulatory_requirements}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.STATUTORY_REGULATORY_REQUIREMENTS}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        statutory_regulatory_requirements: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                <InputLabel id="other-registrations-select">
                  {STRING.ADD_TASK.OTHER_REGISTRATIONS}
                </InputLabel>
                <Select
                  labelId="other-registrations-select"
                  id="other_registrations"
                  value={clientDetails.other_registrations || ""}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, other_registrations: e.target.value },
                    })
                  }
                  label={STRING.ADD_TASK.SELECT_OTHER_REGISTRATIONS}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="consultant"
                  type="text"
                  value={clientDetails.consultant}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.CONSULTANT}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        consultant: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%", mt: 2 }} fullWidth>
                <InputLabel id="iaf-code-select">
                  {STRING.ADD_TASK.IAF_CODE}
                </InputLabel>
                <Select
                  labelId="iaf-code-select"
                  id="iaf_code"
                  value={clientDetails.iaf_code || ""}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, iaf_code: e.target.value },
                    })
                  }
                  label={STRING.ADD_TASK.SELECT_IAF_CODE}
                >
                  {menuItemsIAF.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%"}}>
                <TextField
                  margin="dense"
                  id="representative_role"
                  type="text"
                  value={clientDetails.representative_role}
                  fullWidth
                  variant="standard"
                  label={STRING.ADD_TASK.REPRESENTATIVE_ROLE}
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        representative_role: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
              <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                <TextField
                  id="previous_certifications"
                  label={STRING.ADD_TASK.PREVIOUS_CERTIFICATIONS}
                  value={clientDetails.previous_certifications}
                  multiline
                  rows={12}
                  margin="dense"
                  size="small"                 
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        previous_certifications: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>Scope</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_scope || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_scope: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="large"
                      control={<Radio />}
                      label="Large"
                    />
                    <FormControlLabel
                      value="small"
                      control={<Radio />}
                      label="Small"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>Process</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_process || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_process: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="repetitive"
                      control={<Radio />}
                      label="Repetitive"
                    />
                    <FormControlLabel
                      value="unique"
                      control={<Radio />}
                      label="Unique"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_maturity_mgmt_sys || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_maturity_mgmt_sys: !clientDetails.calculator_parameters.calculator_maturity_mgmt_sys,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.MATURITY_OF_MANAGEMENT_SYSTEMS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_client_preparedness || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_client_preparedness: !clientDetails.calculator_parameters.calculator_client_preparedness,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.CLIENT_PREPAREDNESS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_high_level_of_automation || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_high_level_of_automation: !clientDetails.calculator_parameters.calculator_high_level_of_automation,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.HIGH_LEVEL_OF_AUTOMATION}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_off_location_personnel || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_off_location_personnel: !clientDetails.calculator_parameters.calculator_off_location_personnel,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.OFF_LOCATION_PERSONNEL}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_requires_interpreter || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_requires_interpreter: !clientDetails.calculator_parameters.calculator_requires_interpreter,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.REQUIRES_INTERPRETER}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_visiting_temporary_sites || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_visiting_temporary_sites: !clientDetails.calculator_parameters.calculator_visiting_temporary_sites,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.VISITING_TEMPORARY_SITES}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_high_degree_of_regulation || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_high_degree_of_regulation: !clientDetails.calculator_parameters.calculator_high_degree_of_regulation,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.HIGH_DEGREE_OF_REGULATION}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_already_certified_standards || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_already_certified_standards: !clientDetails.calculator_parameters.calculator_already_certified_standards,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.ALREADY_CERTIFIED_STANDARDS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.SIZE_OF_SITE_FOR_NUMBER_OF_PERSONNEL}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_size_of_site_for_number_of_personnel || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_size_of_site_for_number_of_personnel: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="large"
                      control={<Radio />}
                      label="Large"
                    />
                    <FormControlLabel
                      value="small"
                      control={<Radio />}
                      label="Small"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_design_responsible || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_design_responsible: !clientDetails.calculator_parameters.calculator_design_responsible,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.DESIGN_RESPONSIBLE}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_outsourced_functions || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_outsourced_functions: !clientDetails.calculator_parameters.calculator_outsourced_functions,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.OUTSOURCED_FUNCTIONS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.RISK_CATEGORY}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_risk_category || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_risk_category: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label="Low"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.CUSTOMERS_WITH_CRITICAL_INFO}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_customers_with_critical_info || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_customers_with_critical_info: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="all_critical"
                      control={<Radio />}
                      label="All_critical"
                    />
                    <FormControlLabel
                      value="non_critical"
                      control={<Radio />}
                      label="Non_critical"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.IT_INFRASTRUCTURE_COMPLEXITY}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_it_infrastructure_complexity || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_it_infrastructure_complexity: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="many"
                      control={<Radio />}
                      label="Many"
                    />
                    <FormControlLabel
                      value="few"
                      control={<Radio />}
                      label="Few"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.DEPENDENCY_ON_OUTSOURCING_SUPPLIERS}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_dependency_on_outsourcing_suppliers || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_dependency_on_outsourcing_suppliers: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                    />
                    <FormControlLabel
                      value="little"
                      control={<Radio />}
                      label="Little"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ width: "100%"}}>
                  <FormLabel>{STRING.ADD_TASK.INFORMATION_SYSTEM_DEV_IN_HOUSE}</FormLabel>
                  <RadioGroup
                    row
                    value={clientDetails?.calculator_parameters?.calculator_information_system_dev_in_house || "na"}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_information_system_dev_in_house: e.target.value,
                          }
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label="Low"
                    />
                    <FormControlLabel
                      value="na"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_outsourced_functions_ems || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_outsourced_functions_ems: !clientDetails.calculator_parameters.calculator_outsourced_functions_ems,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.OUTSOURCED_FUNCTIONS_EMS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_high_sensitivity_environment || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_high_sensitivity_environment: !clientDetails.calculator_parameters.calculator_high_sensitivity_environment,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.HIGHER_SENSITIVITY_OF_ENVIRONMENT}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_additional_environmental_aspects || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_additional_environmental_aspects: !clientDetails.calculator_parameters.calculator_additional_environmental_aspects,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.ADDITIONAL_OR_UNUSUAL_ENVIRONMENTAL_ASPECTS_OR_REGULATED_CONDITIONS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_high_risk || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_high_risk: !clientDetails.calculator_parameters.calculator_high_risk,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.HIGH_RISK_IF_ACCIDENT_INCIDENT_OR_EMERGENCY}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_views_of_interested_parties || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_views_of_interested_parties: !clientDetails.calculator_parameters.calculator_views_of_interested_parties,
                          }
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.VIEWS_OF_INTERESTED_PARTIES}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_rate_of_accidents || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_rate_of_accidents: !clientDetails.calculator_parameters.calculator_rate_of_accidents,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.RATE_OF_ACCIDENTS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_public_presence || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_public_presence: !clientDetails.calculator_parameters.calculator_public_presence,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.PUBLIC_PRESENCE}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_legal_proceedings || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_legal_proceedings: !clientDetails.calculator_parameters.calculator_legal_proceedings,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.LEGAL_PROCEEDINGS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_large_presence_of_subcontractors || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_large_presence_of_subcontractors: !clientDetails.calculator_parameters.calculator_large_presence_of_subcontractors,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.LARGE_PRESENCE_OF_SUBCONTRACTORS}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_dangerous_substances || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_dangerous_substances: !clientDetails.calculator_parameters.calculator_dangerous_substances,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.DANGEROUS_SUBSTANCES}
              />
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_unknown_legislation_and_language || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_unknown_legislation_and_language: !clientDetails.calculator_parameters.calculator_unknown_legislation_and_language,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.UNKNOWN_LEGISLATION_AND_LANGUAGE}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clientDetails?.calculator_parameters?.calculator_views_of_interested_parties_ohsms || false}
                    onChange={(e) =>
                      actionDispatch({
                        type: ActionName.SET_CLIENT_DETAILS,
                        payload: {
                          ...clientDetails,
                          calculator_parameters: {
                            ...clientDetails.calculator_parameters,
                            calculator_views_of_interested_parties_ohsms: !clientDetails.calculator_parameters.calculator_views_of_interested_parties_ohsms,
                          },
                        },
                      })
                    }
                  />
                }
                sx={{ p: 1 }}
                label={STRING.ADD_TASK.VIEWS_OF_INTERESTED_PARTIES_OHSMS}
              />
              </Grid>

            </Grid>
            
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ClientAddress state={clientAdressState}
              dispatch={dispatchClientAddress}
              clientAdressList={clientAdressList}
              setClientAdressList={setClientAdressList}
              clientDetails={clientDetails}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Box>
              <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                <TextField
                  margin="dense"
                  size="small"
                  label={"Action / Reminder"}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={reminderToAdd.action}
                  onChange={(e) => {
                    setReminderToAdd((prev) => {
                      return { ...prev, action: e.target.value };
                    });
                  }}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <DatePicker
                  selected={reminderToAdd.due_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    setReminderToAdd((prev) => {
                      return { ...prev, due_date: date };
                    });
                  }}
                  customInput={
                    <TextField
                      size="small"
                      margin="dense"
                      id="Due Date"
                      label={"Due Date"}
                      type="text"
                      value={reminderToAdd.due_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <Autocomplete
                  {...defaultProps}
                  id="user-select"
                  value={assignedUser}
                  onChange={(event: any, newValue: any | null) => {
                    setAssignedUser(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField sx={{marginTop: '5px'}} margin="dense" {...params} label="Assigned To" variant="standard" />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <TextField
                  margin="dense"
                  label={"Note"}
                  type="text"
                  size="small"
                  fullWidth
                  variant="standard"
                  value={reminderToAdd.notes}
                  onChange={(e) => {
                    setReminderToAdd((prev) => {
                      return { ...prev, notes: e.target.value };
                    });
                  }}
                />
              </FormControl>

              <Box sx={{ margin: "15px 0" }}>
                <Button
                  onClick={handleAddReminder}
                  variant="contained"
                  sx={{ backgroundColor: "#000" }}
                >
                  {!isEditReminder ? 'Add Action' : 'Edit Action'}
                </Button>

                {/* bottom table show records  */}
                <Box
                  sx={{
                    backgroundColor: "#F7F7F7",
                    marginTop: "25px",
                    padding: "15px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{ backgroundColor: "#F7F7F7", boxShadow: "none" }}
                  >
                    <Table
                      sx={{ minWidth: 650, fontSize: 12 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Action / Reminders</TableCell>
                          <TableCell>Assigned To</TableCell>
                          <TableCell>Due Date</TableCell>
                          <TableCell>Notes</TableCell>
                          <TableCell>Action Sent Date</TableCell>
                          <TableCell>Done</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(remindersState)?.loading ? (
                          <Typography>Loading</Typography>
                        ) : (remindersState.reminders.map((reminder: any) => (
                              <TableRow
                                key={reminder?.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{reminder.action}</TableCell>
                                <TableCell>{getAssignedTo(reminder.assigned_to)}</TableCell>
                                <TableCell>{reminder.due_date}</TableCell>
                                <TableCell>{reminder.notes}</TableCell>
                                <TableCell>{reminder.date_sent}</TableCell>
                                <TableCell>
                                  <Switch
                                    checked={reminder.is_done}
                                    onChange={(event) => handleDoneReminder(event,reminder)}                                                              
                                    name="is_done"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                  />                                 
                                </TableCell>
                                <TableCell>
                                  <Box sx={{ display: "flex" }}>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      sx={{ minWidth: "auto", p: 0.5, mr: 1 }}
                                      onClick={() => handleEditReminder(reminder)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </Button>
                                    <Button
                                      size="small"
                                      color="error"
                                      variant="contained"
                                      sx={{ minWidth: "auto", p: 0.5, mr: 1 }}
                                      onClick={() => showDeleteReminderConfirm(reminder)}                                      
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      sx={{ minWidth: "auto", p: 0.5, mr: 2 }}
                                      onClick={() => {sendReminder(reminder?.id)}}
                                    >
                                      <NotificationAddIcon fontSize="small" />
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box>
              <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                <TextField
                  id="client-details-notes"
                  label=""
                  value={clientDetails.notes}
                  multiline
                  rows={12}
                  margin="dense"
                  size="small"                 
                  onChange={(e) =>
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: {
                        ...clientDetails,
                        notes: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
            {/*<DatePicker
              selected={clientDetails.movement_date ? new Date(clientDetails.movement_date) : null}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {}}
              readOnly={true}
            />*/}
            <Box height="300px">
              <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <DatePicker
                  selected={clientDetails.movement_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => {
                    actionDispatch({
                      type: ActionName.SET_CLIENT_DETAILS,
                      payload: { ...clientDetails, movement_date: date },
                    });
                  }}
                  customInput={
                    <TextField
                      margin="dense"
                      id="movement_date"
                      label={STRING.ADD_TASK.MOVEMENT_DATE}
                      type="text"
                      value={clientDetails.movement_date}
                      fullWidth
                      variant="standard"
                    />
                  }
                />
              </FormControl>
              </Box>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTask}>Cancel</Button>
          <Button onClick={() => addTaskToList(true)}>Submit</Button>
          <Button onClick={() => addTaskToList(true)}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
